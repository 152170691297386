import { BarChartOutlined, BarsOutlined, DoubleLeftOutlined, DoubleRightOutlined, EnvironmentOutlined, EnvironmentTwoTone, LaptopOutlined, NotificationOutlined, NotificationTwoTone, ShopOutlined, SoundOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleBizRoutes from '../../../routing/GoogleBizRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './SideMenu.scss';
import { ROLES, useIsAuthorized } from '../../pages/common/roles/Roles';

const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

export const GoogleBizLayout = () => {

	const isAuthorized = useIsAuthorized();
	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>
			<Sider width={140} className='container-space' collapsed={collapsed} collapsible={true} trigger={null}>
				<aside>
					<nav>
						<ul>
							<Link to="/negocios/locales">
								<li className={`${(pathName == '/negocios/locales') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<ShopOutlined style={{color: (pathName == '/negocios/locales') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/negocios/locales') ? 'selected' : 'no-selected' }`}>Fichas GBP</span>
									</div>
								</li>
							</Link>
							{
								isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT']) &&  
								<Link to="/negocios/web">
									<li className={`${(pathName == '/negocios/web') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
										<div>
											<LaptopOutlined style={{color: (pathName == '/negocios/web') ? black : grey }} className='icon-menu-item'/>
											<span className={`${(pathName == '/negocios/web') ? 'selected' : 'no-selected' }`}>Web</span>
										</div>
									</li>
								</Link>
							}
							<Link to="/negocios/global">
								<li className={`${(pathName == '/negocios/global') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BarChartOutlined style={{color: (pathName == '/negocios/global') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/negocios/global') ? 'selected' : 'no-selected' }`}>Estadísticas</span>
									</div>
								</li>
							</Link>							
						</ul>
					</nav>
				</aside>
			</Sider>			
			<Content className='container-space'>
				<div>
					<GoogleBizRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};