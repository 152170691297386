import { BarChartOutlined, BarsOutlined, SoundOutlined } from '@ant-design/icons';
import { Layout as AntdLayout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './SideMenu.scss';
import ContentsRoutes from '../../../routing/ContentsRoutes';

const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

const ContentsLayout = () => {

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>
			<Sider width={140} className='container-space' collapsed={collapsed} collapsible={true} trigger={null}>
				<aside>
					<nav>
						<ul>
							<Link to="/contenidos/locales">
								<li className={`${(pathName == '/contenidos/locales') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BarsOutlined style={{color: (pathName == '/contenidos/locales') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/contenidos/locales') ? 'selected' : 'no-selected' }`}>Locales</span>
									</div>
								</li>
							</Link>
							<Link to="/contenidos/publicaciones">
								<li className={`${(pathName == '/contenidos/publicaciones') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<SoundOutlined style={{color: (pathName == '/contenidos/publicaciones') ? black : grey} } className='icon-menu-item'/>
										<span className={`${(pathName == '/contenidos/publicaciones') ? 'selected' : 'no-selected' }`}>{t('dictionary.posts')}</span>
									</div>
								</li>
							</Link>
							<Link to="/contenidos/global">
								<li className={`${(pathName == '/contenidos/global') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BarChartOutlined style={{color: (pathName == '/contenidos/global') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/contenidos/global') ? 'selected' : 'no-selected' }`}>Estadísticas</span>
									</div>
								</li>
							</Link>							
						</ul>
					</nav>
				</aside>
			</Sider>			
			<Content className='container-space'>
				<div>
					<ContentsRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};

export default ContentsLayout;